<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn style="height: 30px" elevation="0" block v-on="on" @click="fetch">
        {{ $t("accounts.configuration") }}
      </v-btn>
    </template>
    <v-card id="microblading-marketplace">
      <v-card-title>{{ $tc("marketplace", 2) }}</v-card-title>
      <v-card-subtitle class="mt-0 primary--text">{{
        $t("marketplace_explication")
      }}</v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>
              <v-icon color="primary" small class="mr-1">$clip</v-icon>Foto
            </th>
            <th class="text-uppercase text-decoration-underline">Parte</th>
            <th
              class="text-uppercase text-decoration-underline"
              v-if="
                $store.getters['auth/getSetting'](
                  'marketplace_active_microblading'
                ) === 1
              "
            >
              {{ $t("price") }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(part, i) in parts_list" :key="i">
            <td>
              <v-file-input
                prepend-icon=""
                v-model="part.new_file"
                class="colorBarrita pointer"
                @click.stop
                :placeholder="clearName(part.src)"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </td>
            <td>
              <v-text-field
                class="colorBarrita"
                v-model="part.part"
              ></v-text-field>
            </td>
            <td
              v-if="
                $store.getters['auth/getSetting'](
                  'marketplace_active_microblading'
                ) === 1
              "
            >
              <v-text-field
                class="colorBarrita"
                suffix="€"
                v-model="part.price"
              ></v-text-field>
            </td>
            <td>
              <v-btn icon @click="del(i)"
                ><v-icon>mdi-window-close</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="99">
              <v-btn fab x-small outlined @click="add"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-text="$t('cancel')"
          @click="dialog = false"
          outlined
          style="height: 25px"
        />
        <v-btn
          type="submit"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
          @click="update"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MarketplaceMicrobladingConfiguration",
  data() {
    return {
      dialog: false,
      parts_list: [],
    };
  },

  mounted() {
    this.fetch();
  },
  methods: {
    add() {
      this.parts_list.push({});
    },
    del(i) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() => {
        this.parts_list.splice(i, 1);
      });
    },
    ...mapActions("marketplace", ["getAllMarketplace", "updateMarketplace"]),
    fetch() {
      this.getAllMarketplace({ filters: { marketplace: "microblading" } }).then(
        (response) => {
          this.parts_list = response;
        }
      );
    },

    clearName(name) {
      if (name) {
        name = name.split("/");
        return name[name.length - 1];
      }
    },
    update() {
      let formData = new FormData();
      this.parts_list.forEach((part, i) => {
        formData.append("marketplaces[" + i + "][part]", part.part);
        if (part.new_file)
          formData.append("marketplaces[" + i + "][file]", part.new_file);
        if (part.price)
          formData.append("marketplaces[" + i + "][price]", part.price);
      });
      console.log(formData);
      this.updateMarketplace({
        type: "microblading",
        formData,
      }).then((response) => {
        if (response.success) {
          this.dialog = false;
          this.$alert(this.$t("save_ok"));
        }
      });
    },
  },
};
</script>

<style lang="sass">
#microblading-marketplace
  table
    min-width: 600px
  th:not(th:last-child),
  td:not(td:last-child)
    font-size: 14px !important
    width: 200px
</style>